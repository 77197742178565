.filter {
  display: flex;
  div {
    padding-right: 10px;
  }
}
.filter-expanded {
  display: flex;
  padding-top: 10px;
  div {
    padding-right: 10px;
  }
}

.filter-container {
  padding-bottom: 10px;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.filter-price {
  width: 130px;
}
.filter-storage {
  width: 150px;
}
.filter-memory {
  width: 150px;  
}
.filter-expand-container {
  display: flex;
  align-items: end;
}
.filter-expand {
  height: 35px;
}
.filter-cpuCompare {
  width: 170px;
}
.filter-checkbox-container {
  display: flex;
  align-items: center;
}
.cpuBlacklist {
  width: 250px;
}

.tableContainer {
  width: 1000px;
  overflow: auto;
}

.containerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.title {
  padding-bottom: 15px;;
}
